<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="outstoragelist" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['mpn','productname']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :lazy="true" :paginator="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入MPN或产品名称模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.mpn" @click="openOutStorage(slotProps.data)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable" :loadDataAfterMethod="loadDataAfter"
                :childNewDataConfig="childNewDataConfig" :CellEditCompleteMethod="cellEditComplete"
                :pageJsonConfig="pageJsonConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="选择出库产品" v-model:visible="showProductDialog" @after-hide="outstockWinHidden" :style="{width: '80vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <DataTable v-if="!startoutstock" :value="products" v-model:filters="productFilters1" class="p-mt-3"
                v-model:selection="selectedProductRow" :metaKeySelection="false" :filters="productFilters1"
                filterDisplay="menu" dataKey="id" :globalFilterFields="['mpn']" scrollable scrollHeight="300px"
                responsiveLayout="scroll" :loading="loading" :lazy="true" :paginator="true" rows="50"
                :totalRecords="totalProductRecords" :first="firstProduct"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" @page="listProductPage($event)">
                <template #header>
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                            @click="clearProductFilter1()" />
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="productFilters1['global'].value" placeholder="输入MPN查询"
                                @keyup.enter="customProductFilter();" style="width: 100%" />
                        </span>
                    </div>
                </template>
                <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                </Column>
                <Column field="mpn" bodyStyle="min-width:160px;left:0px" headerStyle="min-width:160px;left:0px"
                    :showFilterOperator="false" header="MPN" frozen :filterMatchModeOptions="matchModes"
                    :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="sku" bodyStyle="min-width:160px" style="min-width:160px" :showFilterOperator="false"
                    header="SKU" :filterMatchModeOptions="matchModes" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="name" bodyStyle="min-width:180px" style="min-width:180px" :showFilterOperator="false"
                    :filterMatchModeOptions="matchModes" header="产品名称" :sortable="true">
                    <template #body="slotProps">
                        <label class="oneline"
                            v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="allquantity" bodyStyle="min-width:140px" style="min-width:140px"
                    :showFilterOperator="false" :filterMatchModeOptions="numMatchModes" header="总库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="quantity" bodyStyle="min-width:150px" style="min-width:150px" :showFilterOperator="false"
                    :filterMatchModeOptions="numMatchModes" header="可售库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="futuresquantity" bodyStyle="min-width:150px" style="min-width:150px"
                    :showFilterOperator="false" :filterMatchModeOptions="numMatchModes" header="期货库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="processquantity" bodyStyle="min-width:160px" style="min-width:160px"
                    :showFilterOperator="false" :filterMatchModeOptions="numMatchModes" header="在产库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="shipmentquantity" bodyStyle="min-width:160px" style="min-width:160px"
                    :showFilterOperator="false" header="在途库存" :sortable="true" :filterMatchModeOptions="numMatchModes">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="subscribequantity" bodyStyle="min-width:160px" style="min-width:160px"
                    :showFilterOperator="false" :filterMatchModeOptions="numMatchModes" header="预定库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="url" bodyStyle="min-width:100px" style="min-width:100px" header="产品图片">
                    <template #body="slotProps">
                        <Image :src="getFilePatch(slotProps.data.url)" width="50" preview />
                    </template>
                </Column>
                <Column field="productstatusname" bodyStyle="min-width:160px" style="min-width:160px"
                    :showFilterOperator="false" :filterMatchModeOptions="matchModes" header="产品状态" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
            </DataTable>
        </p>
        <p class="p-m-0">
            <DataTable v-if="!startoutstock" :value="selectedProductRow" class="p-mt-3" :metaKeySelection="false"
                dataKey="id" scrollable scrollHeight="350px" responsiveLayout="scroll" :lazy="false" :paginator="false">
                <template #header>
                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                        <span class="text-xl text-900 font-bold">已选择产品</span>
                    </div>
                </template>
                <Column field="mpn" bodyStyle="min-width:160px;left:0px" headerStyle="min-width:160px;left:0px"
                    header="MPN" frozen>
                </Column>
                <Column field="sku" bodyStyle="min-width:160px" style="min-width:160px" header="SKU">
                </Column>
                <Column field="name" bodyStyle="min-width:180px" style="min-width:180px" header="产品名称">
                    <template #body="slotProps">
                        <label class="oneline"
                            v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                    </template>
                </Column>
                <Column field="allquantity" bodyStyle="min-width:140px" style="min-width:140px" header="总库存">
                </Column>
                <Column field="outquantity" bodyStyle="min-width:130px" style="min-width:130px" header="出库数量">
                    <template #body="slotProps">
                        <InputNumber inputStyle="width:100px" v-model="slotProps.data.outquantity" />
                    </template>
                </Column>
                <Column field="outtime" bodyStyle="min-width:120px" style="min-width:120px" header="出库时间">
                    <template #body="slotProps">
                        <Calendar :showTime="false" v-model="slotProps.data['outtime']" hourFormat="24"
                            dateFormat="yy-mm-dd" style="width:110px" />
                    </template>
                </Column>
                <Column field="ordertype" bodyStyle="min-width:150px" style="min-width:150px" header="出库类型">
                    <template #body="slotProps">
                        <Dropdown style="width:130px" v-model="slotProps.data.ordertype" :options="ordertypedict"
                            optionLabel="name" optionValue="code" placeholder="请选择出库类型" />
                    </template>
                </Column>
                <Column field="marginorder" bodyStyle="min-width:130px" headerStyle="width:130px" header="Margin order">
                    <template #body="slotProps">
                        <InputNumber inputStyle="width:120px" v-model="slotProps.data.marginorder" mode="decimal"
                            :useGrouping="false" />
                    </template>
                </Column>
                <Column field="quantity" bodyStyle="min-width:150px" style="min-width:150px" header="可售库存">
                </Column>
                <Column field="futuresquantity" bodyStyle="min-width:150px" style="min-width:150px" header="期货库存">
                </Column>
                <Column field="processquantity" bodyStyle="min-width:160px" style="min-width:160px" header="在产库存">
                </Column>
                <Column field="shipmentquantity" bodyStyle="min-width:160px" style="min-width:160px" header="在途库存">
                </Column>
                <Column field="subscribequantity" bodyStyle="min-width:160px" style="min-width:160px" header="预定库存">
                </Column>
                <Column field="url" bodyStyle="min-width:100px" style="min-width:100px" header="产品图片">
                    <template #body="slotProps">
                        <Image :src="getFilePatch(slotProps.data.url)" width="50" preview />
                    </template>
                </Column>
                <Column field="productstatusname" bodyStyle="min-width:160px" style="min-width:160px" header="产品状态">
                </Column>
                <template #footer> 共 {{ selectedProductRow ? selectedProductRow.length : 0 }} 条记录.</template>
            </DataTable>
        </p>
        <div v-if="startoutstock" class="grid p-fluid mt-3">
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImportData" />
                <Button v-if="!startoutstock" label="从excel导入" icon="pi pi-check" :loading="butLoading"
                    @click="importExcelData" />
                <Button v-if="!startoutstock" label="生成出库单" icon="pi pi-check" :loading="butLoading"
                    @click="saveOutStock" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="调货出库" v-model:visible="transferoutstockShow" @after-hide="outstockWinHidden"
        :style="{width: '70vw'}" :modal="true">
        <DataTable v-if="!startoutstock" :value="transferorders" dataKey="id" scrollable scrollHeight="250px"
            responsiveLayout="scroll" :loading="loading" class="p-mt-3">
            <template #header>
                <div class="flex justify-content-center align-items-center">
                    <h6 class="m-0">待调货订单</h6>
                </div>
            </template>
            <Column field="orderid" style="min-width:150px" header="OrderId" frozen>
            </Column>
            <Column field="name" style="min-width:130px" header="Name">
            </Column>
            <Column field="buyercode" style="min-width:150px" header="BuyerCode">
            </Column>
            <Column field="mpn" style="min-width:150px" header="MPN">
            </Column>
            <Column field="itemcode" style="min-width:130px" header="ItemCode">
            </Column>
            <Column field="productname" style="min-width:180px" header="ProductName">
            </Column>
            <Column field="salesdate" style="min-width:150px" header="SalesDate">
            </Column>
            <Column field="salesquantity" style="min-width:150px" header="需调货数">
            </Column>
            <Column field="unitprice" style="min-width:150px" header="UnitPrice">
            </Column>
            <Column field="ordertype" style="min-width:180px" header="OrderType">
            </Column>
            <Column field="transferorder" style="min-width:120px" header="调货订单">
            </Column>
            <Column style="min-width:130px" header="操作">
                <template #body="slotProps">
                    <Button label="调货" @click="opentransferOutStock(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
        </DataTable>
        <div v-if="startoutstock" class="grid p-fluid mt-3">
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
        </div>
        <template #footer>
            <Button v-if="startoutstock" label="关闭" icon="pi pi-power-off" @click="closetransferSaveOutStock" />
            <Button v-if="!startoutstock" label="生成出库单" icon="pi pi-check" :loading="butLoading"
                @click="savetransferOutStock" autofocus />
        </template>
    </Dialog>
    <Dialog header="选择调货订单" v-model:visible="cantransferordersShow" :style="{width: '70vw'}" :modal="true">
        <DataTable :value="cantransferorders" dataKey="id" v-model:selection="selectedRow3" v-model:filters="filters3"
            filterDisplay="menu" :globalFilterFields="['orderid','name','buyercode']" scrollable scrollHeight="250px"
            responsiveLayout="scroll" :loading="loading" class="p-mt-3">
            <template #header>
                <div class="flex justify-content-center align-items-center">
                    <h6 class="m-0">可调货订单</h6>
                </div>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters3['global'].value" placeholder="输入关键字查询" style="width: 100%" />
                </span>
            </template>
            <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
            </Column>
            <Column field="orderid" bodyStyle="min-width:150px;left:0px" headerStyle="min-width:150px;left:0px"
                header="OrderId" frozen>
            </Column>
            <Column field="name" style="min-width:130px" header="Name">
            </Column>
            <Column field="buyercode" style="min-width:150px" header="BuyerCode">
            </Column>
            <Column field="mpn" style="min-width:150px" header="MPN">
            </Column>
            <Column field="itemcode" style="min-width:130px" header="ItemCode">
            </Column>
            <Column field="productname" style="min-width:180px" header="ProductName">
            </Column>
            <Column field="salesdate" style="min-width:150px" header="SalesDate">
            </Column>
            <Column field="salesquantity" style="min-width:150px" header="SalesQuantity">
            </Column>
            <Column field="unitprice" style="min-width:150px" header="UnitPrice">
            </Column>
            <Column field="ordertype" style="min-width:180px" header="OrderType">
            </Column>
            <Column field="transferquantity" style="min-width:130px" header="调货数量">
                <template #body="slotProps">
                    <InputText type="text" style="width:110px" v-model="slotProps.data['transferquantity']" />
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closeCantransferOrders" />
            <Button label="添加" icon="pi pi-check" :loading="butLoading" @click="addtransferOutStock" autofocus />
        </template>
    </Dialog>
    <Dialog header="上传导入数据" v-model:visible="importDisplay" :style="{width: '30vw'}" :maximizable="true" :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="!startImport" class="field col-12 md:col-12">
                <Button label="导入模板下载" @click="getTemplateFilePatch" class="p-button-link" />
            </div>
            <div v-if="!startImport" class="field col-12 md:col-12">
                <FileUpload mode="basic" id="importtemplateFileId" style="width:100%" name="importtemplateFile"
                    :url="uploadurl" accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload"
                    @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                    invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport" />
            </div>
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import Image from 'primevue/image';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import inventoryoutstockPageJson from "@/data/inventoryoutstockConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '740';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                uploadurl: '',
                butLoading: false,
                recordsubject: '',
                readOnly: true,
                displayMaximizable: false,
                displayQueryPlan: false,
                showProductDialog: false,
                importDisplay: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                productFilters1: null,
                currentRow: {
                    bindpage: 'inventoryoutstock',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                childNewDataConfig: {},
                pageJsonConfig: {},
                selectedRow: ref(),
                selectedProductRow: ref(),
                importProductRow: ref(),
                selectedRow3: ref(),
                currenttransferrow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                totalProductRecords: 0,
                firstProduct: 0,
                listTitle: '盘点出库',
                selectedKey: {
                    740: true
                },
                expandedKey: null,
                outstoragelist: [],
                products: [],
                transferorders: [],
                cantransferorders: [],
                allwaitoutorders: [],
                importProgress: '',
                importProgressValue: 0,
                cantransferordersShow: false,
                transferoutstockShow: false,
                outstockShow: false,
                startoutstock: false,
                loading: true,
                nodes: [],
                items: [{
                    label: '出库操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建出库',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createOutStorage();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delOutStorage();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                ordertypedict: [{
                        name: 'PO',
                        code: 'PO'
                    },
                    {
                        name: 'Margin order',
                        code: 'Margin order'
                    },
                    {
                        name: 'Margin agreement deposit',
                        code: 'Margin agreement deposit'
                    },
                ],
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getinventoryoutstock(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.loadMenuTree();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            initProductFilters1() {
                this.productFilters1 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'sku': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'name': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'allquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'futuresquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'processquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'quantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'shipmentquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'subscribequantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'productstatusname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                }
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearProductFilter1() {
                this.initProductFilters1();
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('738', this.currentMenuId, this.$route, this.loadData, this.setlistTitleFun, this
                    .setloadMenuTree);
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '盘点出库', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.displayQueryPlan = false;
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            customProductFilter() {
                setTimeout(() => {
                    this.loadProductData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            listProductPage(event) {
                var page = event.page;
                this.loadProductData(page + 1);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: []
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'mpn,productname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{inventoryoutstocklist(where:" + JSON.stringify(listwhere) +
                    "){id mpn productname ordertype outtime outquantity outprice unitprice creater}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.outstoragelist = jsonData.data.inventoryoutstocklist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            outstockWinHidden() {
                this.loadData(1);
            },
            closeImportData() {
                this.selectedProductRow = null;
                this.showProductDialog = false;
            },
            closetransferSaveOutStock() {
                this.loadData(1);
                this.importProgress = '';
                this.startoutstock = false;
                this.importProgressValue = 0;
                this.transferorders = [];
                this.cantransferorders = [];
                this.transferoutstockShow = false;
            },
            savetransferOutStock() {
                for (var i = 0; i < this.transferorders.length; i++) {
                    if (this.transferorders[i]['transferorderdetail'].indexOf(',') > 0) {
                        var array = this.transferorders[i]['transferorderdetail'].split(',');
                        for (var k = 0; k < array.length; k++) {
                            var array2 = array[k].split('|');
                            var cloneObj = {};
                            for (var key in this.transferorders[i]) {
                                cloneObj[key] = this.transferorders[i][key];
                            }
                            cloneObj[key]['transferorder'] = array2[0];
                            cloneObj[key]['transferorderdetail'] = array[k];
                            cloneObj[key]['salesquantity'] = array2[1];
                            this.allwaitoutorders.push(cloneObj);
                        }
                    } else {
                        var array3 = this.transferorders[i]['transferorderdetail'].split('|');
                        this.transferorders[i]['transferorder'] = array3[0];
                        this.allwaitoutorders.push(this.transferorders[i]);
                    }
                }
                this.startoutstock = true;
                this.importProgressValue = 0;
                this.importProgress = '';
                var outorders = [];
                for (var k2 = 0; k2 < this.allwaitoutorders.length; k2++) {
                    var salesquantitytemp = Number(this.allwaitoutorders[k2]['salesquantity']);
                    if (!isNaN(salesquantitytemp) && salesquantitytemp > 0) {
                        outorders.push(this.allwaitoutorders[k2]);
                    }
                }
                this.createOutStockList(outorders);
            },
            saveOutStock() {
                if (!this.selectedProductRow || this.selectedProductRow.length == 0) {
                    MessageTip.warnmsg('请选择出库产品');
                    return;
                }
                var orderData = [];
                for (var i = 0; i < this.selectedProductRow.length; i++) {
                    var ordertypestr = '';
                    var categoryval = 1;
                    if (!this.selectedProductRow[i]['outquantity'] || parseInt(this.selectedProductRow[i][
                            'outquantity'
                        ]) <= 0) {
                        MessageTip.warnmsg('请填写出库产品数量');
                        return;
                    }
                    if (this.selectedProductRow[i]['outtime'] == '') {
                        MessageTip.warnmsg('请填写实际出库时间');
                        return;
                    }
                    if (this.selectedProductRow[i]['ordertype'] == '') {
                        MessageTip.warnmsg('请选择出库产品的出库类型');
                        return;
                    } else {
                        ordertypestr = this.selectedProductRow[i]['ordertype'];
                    }
                    if (this.selectedProductRow[i]['ordertype'] == 'Margin order') {
                        if (this.selectedProductRow[i]['marginorder'] == '' || parseInt(this.selectedProductRow[i][
                                'marginorder'
                            ]) <= 0) {
                            MessageTip.warnmsg('请填写出库产品的marginorder');
                            return;
                        } else {
                            ordertypestr = ordertypestr + '(' + this.selectedProductRow[i][
                                'marginorder'
                            ] + ')';
                            categoryval = 2;
                        }
                    }
                    if (this.selectedProductRow[i]['ordertype'] == 'Margin agreement deposit') {
                        if (this.selectedProductRow[i]['marginorder'] == '' || parseInt(this.selectedProductRow[i][
                                'marginorder'
                            ]) <= 0) {
                            MessageTip.warnmsg('请填写出库产品的marginorder');
                            return;
                        } else {
                            ordertypestr = ordertypestr + '(' + this.selectedProductRow[i][
                                'marginorder'
                            ] + ')';
                            categoryval = 3;
                        }
                    }
                    orderData.push({
                        id: '-' + this.selectedProductRow[i]['id'],
                        orderid: '-' + this.selectedProductRow[i]['id'],
                        name: '',
                        buyercode: '',
                        mpn: this.selectedProductRow[i]['mpn'],
                        itemcode: this.selectedProductRow[i]['sku'],
                        productname: this.selectedProductRow[i]['name'],
                        salesdate: '',
                        salesquantity: this.selectedProductRow[i]['outquantity'],
                        unitprice: '',
                        pricediscountperunit: '',
                        fulfillmentperunit: '',
                        total: '',
                        finaltotal: '',
                        ordertype: ordertypestr,
                        storeid: sessionStorage.getItem('storeid'),
                        marginorder: this.selectedProductRow[i]['marginorder'],
                        category: categoryval,
                        outtime: this.selectedProductRow[i]['outtime'],
                        originalproductid: '',
                    });
                }
                if (orderData.length > 0) {
                    this.startoutstock = true;
                    this.checkOutStockSuiteList(orderData);
                }
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData) {
                console.log('loadDataAfter...');
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    console.log(v_mainelements);
                    console.log(v_childelements);
                    console.log(v_originalData);
                    console.log(this.importProductRow);
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'products') {
                            v_childelements[k2]['rows'] = [];
                            for (var i = 0; i < this.importProductRow.length; i++) {
                                var productObj = {
                                    id: '-' + this.importProductRow[i]['productid'],
                                    shipmentid: -1,
                                    productid: this.importProductRow[i]['productid'] + '',
                                    producttype: this.importProductRow[i]['producttype'],
                                    shipmentproductid: -1,
                                    platformsku: this.importProductRow[i]['sku'],
                                    platformmpn: this.importProductRow[i]['mpn'],
                                    name: this.importProductRow[i]['name'],
                                    expectedinstoragequantity: 0,
                                    unitprice: this.importProductRow[i]['unitprice'],
                                    difference: 0,
                                    long: this.importProductRow[i]['len'],
                                    width: this.importProductRow[i]['width'],
                                    high: this.importProductRow[i]['high'],
                                    weight: this.importProductRow[i]['weight'],
                                    volume: this.importProductRow[i]['volume'],
                                    url: this.importProductRow[i]['url'],
                                    allvolume: 0,
                                    sharingcost: this.importProductRow[i]['sharingcost'],
                                    instorageprice: this.importProductRow[i]['instorageprice'],
                                    fobprice: this.importProductRow[i]['fobprice'],
                                    isonebox: this.importProductRow[i]['isonebox'],
                                };
                                v_childelements[k2]['rows'].push(productObj);
                            }
                        }
                    }
                }
            },
            createOutStorage() {
                this.selectedProductRow = null;
                this.products = [];
                this.importProductRow = [];
                this.initProductFilters1();
                this.loadProductData(1);
                this.showProductDialog = true;
            },
            delOutStorage() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要删除入库单');
                    return;
                }
                var instorageIds = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    instorageIds.push({
                        "id": this.selectedRow[k1]["id"]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "delete{inventoryoutstocklist(o:" + JSON.stringify(instorageIds) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        MessageTip.successmsg('删除成功');
                    } else {
                        MessageTip.warnmsg('删除失败');
                    }
                });
            },
            loadProductData(v_page) {
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: []
                };
                for (var key in this.productFilters1) {
                    if (key == 'global' && this.productFilters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'mpn',
                            value: this.productFilters1[key]['value'],
                            operation: this.productFilters1[key]['matchMode']
                        });
                    }
                    if (this.productFilters1[key]['constraints']) {
                        for (var i = 0; i < this.productFilters1[key].constraints.length; i++) {
                            if (this.productFilters1[key].constraints[i].value && this.productFilters1[key].constraints[
                                    i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.productFilters1[key].constraints[i].value,
                                    operation: this.productFilters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{stockproductlist(where:" + JSON.stringify(listwhere) +
                    "){id mpn sku name fobprice allquantity futuresquantity processquantity quantity shipmentquantity subscribequantity url productstatusname storeid}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.products = jsonData.data.stockproductlist;
                        this.totalProductRecords = jsonData.page.allrecord;
                        this.firstProduct = 50 * (jsonData.page.pageindex - 1);
                        for (var i = 0; i < this.products.length; i++) {
                            this.products[i]['ordertype'] = '';
                            this.products[i]['outquantity'] = '';
                            this.products[i]['marginorder'] = '';
                            this.products[i]['outtime'] = '';
                        }
                    }
                });
            },
            openOutStorage(v_data) {
                this.recordsubject = '出库单' + v_data.mpn;
                this.currentRow = {
                    bindpage: 'inventoryoutstock',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            checkOutStockSuiteList(outorders) {
                this.butLoading = true;
                this.importProgress = '开始检查订单数据...';
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid"))
                    .post(
                        '/calf/plat/OutstockSuiteCheck?appkey=' + sessionStorage.getItem("appkey"), outorders).then(
                        res => {
                            console.log(res);
                            this.butLoading = false;
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('OutstockSuiteCheck:');
                                console.log(retObj);
                                outorders = retObj;
                                this.importProgress = '订单数据检查完毕';
                                this.checkOutStockList(outorders);
                            } else {
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            checkOutStockList(outorders) {
                var checkData = {
                    data: []
                };
                for (var i = 0; i < outorders.length; i++) {
                    checkData.data.push({
                        buyercode: outorders[i]['buyercode'],
                        itemcode: outorders[i]['itemcode'],
                        marginorder: outorders[i]['marginorder'],
                        mpn: outorders[i]['mpn'],
                        orderid: outorders[i]['orderid'],
                        ordertype: outorders[i]['ordertype'],
                        outquantity: outorders[i]['salesquantity'],
                        outtype: outorders[i]['category'],
                        tblorderid: outorders[i]['id'],
                        storeid: outorders[i]['storeid'],
                    });
                }
                if (checkData.data.length > 0) {
                    this.butLoading = true;
                    requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid"))
                        .post(
                            '/calf/plat/OutstockCheck?appkey=' + sessionStorage.getItem("appkey"), checkData).then(
                            res => {
                                console.log(res);
                                this.butLoading = false;
                                if (res.data.errcode == "0") {
                                    var tipStr = '';
                                    var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                    console.log('checkOutStockList:');
                                    console.log(retObj);
                                    for (var i2 = 0; i2 < retObj.length; i2++) {
                                        if (retObj[i2].err != '') {
                                            tipStr = tipStr + retObj[i2].err + ";";
                                        }
                                    }
                                    console.log(tipStr);
                                    if (tipStr != '') {
                                        this.importProgress = tipStr;
                                    } else {
                                        if (retObj.length > 0) {
                                            this.transferorders = [];
                                            for (var i = 0; i < retObj.length; i++) {
                                                for (var j = 0; j < outorders.length; j++) {
                                                    if (retObj[i]['tblorderid'] == outorders[j]['id']) {
                                                        outorders[j]['salesquantity'] = parseInt(outorders[j][
                                                            'salesquantity'
                                                        ]) - parseInt(retObj[i]['outquantity']);
                                                        var cloneObj = {};
                                                        for (var pro in outorders[j]) {
                                                            cloneObj[pro] = outorders[j][pro];
                                                        }
                                                        cloneObj['salesquantity'] = retObj[i]['outquantity'];
                                                        cloneObj['transferorder'] = '';
                                                        cloneObj['transferorderdetail'] = '';
                                                        this.transferorders.push(cloneObj);
                                                        break;
                                                    }
                                                }
                                            }
                                            this.allwaitoutorders = outorders;
                                            this.outstockShow = false;
                                            this.startoutstock = false;
                                            this.transferoutstockShow = true;
                                        } else {
                                            this.createOutStockList(outorders);
                                        }
                                    }
                                } else {
                                    MessageTip.warnmsg(res.data);
                                }
                            });
                }
            },
            loadCanTransferOrders(v_mpn) {
                this.loading = true;
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'querycantransfer',
                        value: '',
                        operation: 'equals'
                    }, {
                        name: 'finish',
                        value: '1',
                        operation: 'equals'
                    }, {
                        name: 'mpn',
                        value: v_mpn,
                        operation: 'equals'
                    }, {
                        name: 'category',
                        value: '3',
                        operation: 'equals'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{orderlist(where:" + JSON.stringify(listwhere) +
                    "){id orderid name buyercode mpn itemcode productname salesdate salesquantity unitprice pricediscountperunit fulfillmentperunit total finaltotal ordertype isreturnname rmaid storename operator storeid marginorder category}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadCanTransferOrders---');
                        console.log(jsonData);
                        for (var i = 0; i < jsonData.data.orderlist.length; i++) {
                            jsonData.data.orderlist[i]['transferquantity'] = 0;
                        }
                        this.cantransferorders = jsonData.data.orderlist;
                    }
                });
            },
            createOutStockList(outorders) {
                if (outorders.length > 0) {
                    outorders.sort((a, b) => a.outtime.localeCompare(b.outtime));
                    console.log('createOutStockList....');
                    console.log(outorders);
                    this.importProgress = '共' + outorders.length + '条数据,已处理0条,还剩余' + outorders.length + '条';
                    setTimeout(() => {
                        this.continueCreateOutStock(outorders, 0, 0, 0);
                    }, 10);
                }
            },
            continueCreateOutStock(alldatas, importIndex, failureIndex, currentIndex) {
                if (alldatas.length > currentIndex) {
                    this.importProgress = '共' + alldatas.length + '条数据,正处理第' + (currentIndex + 1) +
                        '条数据,已成功处理' + importIndex + '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex -
                            failureIndex) + '条';
                    var orderObj = alldatas[currentIndex];
                    if (orderObj['suiteoutmark'] && orderObj['suiteoutmark'] != '') {
                        var suiteoutmarkstr = orderObj['suiteoutmark'];
                        var outStockBlotterObjs = [];
                        outStockBlotterObjs.push({
                            id: '-1',
                            buyercode: orderObj['buyercode'],
                            fulfillmentperunit: orderObj['fulfillmentperunit'],
                            itemcode: orderObj['itemcode'],
                            marginorder: orderObj['marginorder'],
                            mpn: orderObj['mpn'],
                            name: orderObj['name'],
                            orderid: orderObj['orderid'],
                            ordertype: orderObj['ordertype'],
                            outquantity: orderObj['salesquantity'],
                            outtype: orderObj['category'],
                            productname: orderObj['productname'],
                            salesdate: orderObj['salesdate'],
                            salesquantity: orderObj['salesquantity'],
                            storeid: orderObj['storeid'],
                            tblorderid: orderObj['id'],
                            total: orderObj['total'],
                            unitprice: orderObj['unitprice'],
                            transferorder: orderObj['transferorder'],
                            suiteoutmark: orderObj['suiteoutmark'],
                            instoragecurrency: orderObj['instoragecurrency'],
                            instorageexchangerate: orderObj['instorageexchangerate'],
                            ordercurrency: orderObj['ordercurrency'],
                            orderexchangerate: orderObj['orderexchangerate'],
                            transferorderdetail: orderObj['transferorderdetail'],
                            outtime: orderObj['outtime'],
                            originalproductid: orderObj['originalproductid'],
                        });
                        var startIndex = currentIndex + 1;
                        for (var i = startIndex; i < alldatas.length; i++) {
                            orderObj = alldatas[i];
                            if (orderObj['suiteoutmark'] && orderObj['suiteoutmark'] == suiteoutmarkstr) {
                                outStockBlotterObjs.push({
                                    id: '-1',
                                    buyercode: orderObj['buyercode'],
                                    fulfillmentperunit: orderObj['fulfillmentperunit'],
                                    itemcode: orderObj['itemcode'],
                                    marginorder: orderObj['marginorder'],
                                    mpn: orderObj['mpn'],
                                    name: orderObj['name'],
                                    orderid: orderObj['orderid'],
                                    ordertype: orderObj['ordertype'],
                                    outquantity: orderObj['salesquantity'],
                                    outtype: orderObj['category'],
                                    productname: orderObj['productname'],
                                    salesdate: orderObj['salesdate'],
                                    salesquantity: orderObj['salesquantity'],
                                    storeid: orderObj['storeid'],
                                    tblorderid: orderObj['id'],
                                    total: orderObj['total'],
                                    unitprice: orderObj['unitprice'],
                                    transferorder: orderObj['transferorder'],
                                    suiteoutmark: orderObj['suiteoutmark'],
                                    instoragecurrency: orderObj['instoragecurrency'],
                                    instorageexchangerate: orderObj['instorageexchangerate'],
                                    ordercurrency: orderObj['ordercurrency'],
                                    orderexchangerate: orderObj['orderexchangerate'],
                                    transferorderdetail: orderObj['transferorderdetail'],
                                    outtime: orderObj['outtime'],
                                    originalproductid: orderObj['originalproductid'],
                                });
                            } else {
                                break;
                            }
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "mutation{outstockblotterlist(o:" + JSON.stringify(outStockBlotterObjs) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                importIndex = importIndex + outStockBlotterObjs.length;
                            } else {
                                MessageTip.warnmsg('处理第' + (currentIndex + 1) + "到" + (currentIndex + 1 +
                                        outStockBlotterObjs.length) +
                                    '条数据失败');
                                failureIndex = failureIndex + outStockBlotterObjs.length;
                            }
                            console.log('continueCreateOutStock...suiteoutmark..');
                            console.log(outStockBlotterObjs.length);
                            currentIndex = currentIndex + outStockBlotterObjs.length;
                            this.importProgressValue = (((importIndex + failureIndex) / alldatas.length) * 100)
                                .toFixed(0);
                            console.log('importProgressValue:' + this.importProgressValue);
                            console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                            if (this.startoutstock) {
                                setTimeout(() => {
                                    this.continueCreateOutStock(alldatas, importIndex, failureIndex,
                                        currentIndex);
                                }, 10);
                            }
                        });
                    } else {
                        var outStockBlotterObj = {
                            id: '-1',
                            buyercode: orderObj['buyercode'],
                            fulfillmentperunit: orderObj['fulfillmentperunit'],
                            itemcode: orderObj['itemcode'],
                            marginorder: orderObj['marginorder'],
                            mpn: orderObj['mpn'],
                            name: orderObj['name'],
                            orderid: orderObj['orderid'],
                            ordertype: orderObj['ordertype'],
                            outquantity: orderObj['salesquantity'],
                            outtype: orderObj['category'],
                            productname: orderObj['productname'],
                            salesdate: orderObj['salesdate'],
                            salesquantity: orderObj['salesquantity'],
                            storeid: orderObj['storeid'],
                            tblorderid: orderObj['id'],
                            total: orderObj['total'],
                            unitprice: orderObj['unitprice'],
                            transferorder: orderObj['transferorder'],
                            suiteoutmark: orderObj['suiteoutmark'],
                            instoragecurrency: orderObj['instoragecurrency'],
                            instorageexchangerate: orderObj['instorageexchangerate'],
                            ordercurrency: orderObj['ordercurrency'],
                            orderexchangerate: orderObj['orderexchangerate'],
                            transferorderdetail: orderObj['transferorderdetail'],
                            outtime: orderObj['outtime'],
                            originalproductid: orderObj['originalproductid'],
                        };
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "mutation{outstockblotter(o:" + JSON.stringify(outStockBlotterObj) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                importIndex = importIndex + 1;
                            } else {
                                MessageTip.warnmsg('处理第' + (currentIndex + 1) + '条数据失败');
                                failureIndex = failureIndex + 1;
                            }
                            currentIndex = currentIndex + 1;
                            this.importProgressValue = (((importIndex + failureIndex) / alldatas.length) * 100)
                                .toFixed(0);
                            console.log('importProgressValue:' + this.importProgressValue);
                            console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                            if (this.startoutstock) {
                                setTimeout(() => {
                                    this.continueCreateOutStock(alldatas, importIndex, failureIndex,
                                        currentIndex);
                                }, 10);
                            }
                        });
                    }
                } else {
                    this.importProgress = '共' + alldatas.length + '条数据,已成功处理' + importIndex +
                        '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex - failureIndex) + '条';
                }
            },
            opentransferOutStock(v_row) {
                this.cantransferordersShow = true;
                this.currenttransferrow = v_row;
                this.selectedRow3 = null;
                this.loadCanTransferOrders(v_row['mpn']);
            },
            addtransferOutStock() {
                if (!this.selectedRow3 || this.selectedRow3.length == 0) {
                    MessageTip.warnmsg('请选择要调货的订单');
                    return;
                }
                var salesquantity = Number(this.currenttransferrow['salesquantity']);
                var transferquantity = 0;
                var transferorder = '';
                var transferorderdetail = '';
                for (var i = 0; i < this.selectedRow3.length; i++) {
                    var tempnum = Number(this.selectedRow3[i]['transferquantity']);
                    if (!isNaN(tempnum)) {
                        transferquantity = transferquantity + tempnum;
                        if (transferorder == '') {
                            transferorder = this.selectedRow3[i]['orderid'];
                            transferorderdetail = this.selectedRow3[i]['id'] + '|' + tempnum;
                        } else {
                            transferorder = transferorder + ',' + this.selectedRow3[i]['orderid'];
                            transferorderdetail = transferorderdetail + ',' + this.selectedRow3[i][
                                'id'
                            ] + '|' + tempnum;
                        }
                    }
                }
                if (transferquantity != salesquantity) {
                    MessageTip.warnmsg('选择的调货的订单调货数量和需求数量不一致');
                    return;
                }
                this.currenttransferrow['transferorder'] = transferorder;
                this.currenttransferrow['transferorderdetail'] = transferorderdetail;
                this.cantransferordersShow = false;
                this.selectedRow3 = null;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            closeCantransferOrders() {
                this.cantransferordersShow = false;
            },
            onImportUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/InventoryOutStockImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            this.selectedProductRow = [];
                            console.log(unescape(Base64.decode(res.data)));
                            var excelDatas = JSON.parse(unescape(Base64.decode(res.data)));
                            for (var j = 0; j < excelDatas.length; j++) {
                                this.selectedProductRow.push(excelDatas[j]);
                            }
                            this.importDisplay = false;
                        }
                    });
                } else {
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeImportUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '1');
                event.formData.append('attachttype', '1');
            },
            importExcelData() {
                this.importDisplay = true;
            },
            closeImport() {
                this.importDisplay = false;
            },
            getTemplateFilePatch() {
                location.href = '/calf/plat/file/exceltemplate/盘点出库模板.xlsx?appkey=' + sessionStorage.getItem("appkey");
                return;
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
            Image,
        }
    }
</script>